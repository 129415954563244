"use client";
import { useCallback, useEffect, useState } from "react";
import NextLink from "next/link";
import { LinkProps as NextLinkProps } from "next/link";
import cn from "@/libs/cn";
import useClientContext from "@/context/ClientContext";
import useClientRouter from "@/hooks/useClientRouter";

import styles from "./link.module.scss";

export interface LinkProps extends Omit<NextLinkProps, "href"> {
  href: string | (() => string);
  scroll?: boolean;
  target?: string;
  module?: string;
  wrapClassName?: string;
  title?: string;
  stopClickEvent?: boolean;
  disabled?: boolean;
  className?: string;
  classNameActive?: string;
  classNameNotActive?: string;
  children?: any;
  exactMatch?: boolean;
}

const Link = ({
  href,
  scroll,
  target,
  module,
  children,
  title,
  stopClickEvent,
  onClick: onClickOut = () => {},
  disabled,
  className,
  classNameActive,
  classNameNotActive,
  exactMatch = true,
  ...rest
}: LinkProps) => {
  const { locale, isSeller } = useClientContext();
  const { pathname, search } = useClientRouter();

  const path = typeof href === "function" ? href() : href;
  const preHref = isSeller ? path : `/${locale}${path}`;

  const [active, setActive] = useState(false);

  const onClick = useCallback(
    (e: MouseEvent) => {
      if (stopClickEvent) {
        e.stopPropagation();
        e.preventDefault();
      }
      // @ts-ignore
      onClickOut(e);
    },
    [onClickOut, stopClickEvent]
  );

  useEffect(() => {
    const asPath = search ? `${pathname}?${search}` : pathname;
    setActive(exactMatch ? preHref === asPath : `${asPath}`.startsWith(`${preHref}`));
  }, [preHref, pathname, search, exactMatch]);

  return (
    <NextLink
      {...rest}
      href={disabled ? "#" : preHref}
      title={title}
      target={target}
      scroll={scroll}
      className={cn(styles.Link, active ? classNameActive : classNameNotActive, className)}
      // @ts-ignore
      onClick={onClick}
    >
      {children}
    </NextLink>
  );
};

export default Link;
